
import {computed, defineComponent, onMounted, ref,} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
//import {Field} from "vee-validate";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";
import flatpickr from "flatpickr";
import {Turkish} from "flatpickr/dist/l10n/tr";
import 'flatpickr/dist/flatpickr.css';
import {utils, writeFileXLSX} from "xlsx";


interface srcItem {
  srcDate: string;
  srcAccount: number;
}


export default defineComponent({
  name: "hotel-arrival-house-report",
  components: {
    //Field,
  },

  setup() {

    onMounted(() => {
      setCurrentPageBreadcrumbs("Giriş Bazlı Oda Raporu", ["Raporlar", "Liste"]);
      setDatePicker();
    });

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const arrHotelList = ref([]);

    function getHotelByID(prmHotelID) {
      for (let i=0; i<arrHotelList.value.length; i++){
        let each = arrHotelList.value[i];
        if(each.HotelID === prmHotelID){

          return each;
        }
      }

      return null;
    };

    function fillHotelList (prmData) {

      if (prmData !== undefined){
        for (let i=0; i<prmData.length; i++){
          let each = prmData[i];
          let eachHotel = getHotelByID(each.SupplierID);

          if(each.RemarksEnum1!=='CANCEL CONFIRM'){

            if (eachHotel===null){
              const ArrAccountsAll = getReservationProductsByHotelID(prmData, each.SupplierID);
              const ArrAccountGruop= [];

              for (let j=0; j<ArrAccountsAll.length; j++){
                let eachAcc = ArrAccountsAll[j];
                const tempAccountGroup = getItemInArray(ArrAccountGruop, eachAcc.AccountID);
                if(tempAccountGroup===null){
                  if(eachAcc.RemarksEnum1!=='CANCEL CONFIRM'){
                    ArrAccountGruop.push({
                      AccountID: eachAcc.AccountID,
                      RoomCount: Number(eachAcc.ProductCount),
                      AccountTitle: eachAcc.AccountTitle,
                      ArrAccReservations:getReservationProductsByHotelIDAndAccountID(prmData, eachAcc.SupplierID, eachAcc.AccountID, eachAcc.RemarksEnum1),
                    });
                  }
                }else {
                  if(eachAcc.RemarksEnum1!=='CANCEL CONFIRM'){
                    tempAccountGroup.RoomCount+=Number(eachAcc.ProductCount);
                  }
                }
              }
              //if(each.RemarksEnum1!=='CANCEL CONFIRM'){
                arrHotelList.value.push({
                  HotelID: each.SupplierID,
                  HotelTitle: each.Title,
                  ArrAccounts: ArrAccountGruop,
                  RoomCount:Number(each.ProductCount),
                })
              //}
            }else{
             // if(each.RemarksEnum1!=='CANCEL CONFIRM'){
                eachHotel.RoomCount+=Number(each.ProductCount);
             // }
            }

          }

        }
      }

    };


    function getItemInArray(prmArray, prmAccountID) {
      let rv = null;
      prmArray.forEach((each) => {
        if(prmAccountID===each.AccountID){
          rv= each;
          return each;
        }
      });
      return rv;
    }

    function getReservationProductsByHotelID (prmArray, prmHotelID){
      const confirmResults = prmArray.filter(obj =>{
        return obj.SupplierID === prmHotelID + "";
      })
      return confirmResults;
    };

    function getReservationProductsByHotelIDAndAccountID (prmArray, prmHotelID, prmAccountID, prmRemarksEnum1){
      console.log("getReservationProductsByHotelIDAndAccountID " + prmHotelID + " " +prmAccountID );
      const confirmResults = prmArray.filter(obj =>{
        return obj.SupplierID === prmHotelID + "" &&  obj.AccountID=== prmAccountID + "" && obj.RemarksEnum1=== prmRemarksEnum1 + "";
      })
      return confirmResults;
    };


    const srcItem = ref<srcItem>({
      srcDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcAccount: 0,
    });

    function setDatePicker() {
      let tmpSrcDate = flatpickr("#srcDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
    }

    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    const myAccountsTitle = computed(() => {
      let tmpArray = [];
      if (myAccountList.value.data !== undefined) {
        for (var i of myAccountList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });

    const getAccounts = () => {
      let myAccounts = document.getElementById("srcAccounts") as HTMLInputElement;
      let myPayload = {};
      if (myAccounts.value.length == 3) {
        myPayload = {
          'Title': myAccounts.value
        }
        store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
      }
    }
    const filterAccounts = (val) => {
      if (myAccountList.value.data != null) {
        return (myAccountList.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return true;
      }
    };

    function search() {
      if (!submitButtonRef.value) {
        return;
      }
      arrHotelList.value = [];
      let myAccount = document.getElementById("srcAccounts") as HTMLInputElement;
      const payloadProductList = {
        ProductType: "HOTEL",
        // is_active: "active",
        //ProcessDate: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
        ProcessDateStart: "",
        ProcessDateFinish: "",
        StartDate2: srcItem.value.srcDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcDate) : "",
        StartDate1: srcItem.value.srcDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcDate) : "",
        is_active: "active",
        AccountID: myAccount.value ? filterAccounts(myAccount.value)[0].ID : "",
      }

      store.dispatch(Actions.RESERVATION_PRODUCTS_LIST_2, payloadProductList).then((payload) => {
        fillHotelList(payload.data);
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      }).catch((payload) => {
        libTools.method.swalError("Tamam", "Hata Oluştu");
      });
      submitButtonRef.value.disabled = false;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
    }


    return {
      dateConvert,
      libTools,
      submitButtonRef,
      srcItem,
      search,
      myAccountList,
      myAccountsTitle,
      getAccounts,
      arrHotelList,
      getReservationProductsByHotelID,
    }
  },

});
